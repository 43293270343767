$font-family-sans-serif: Muli, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif

$gold: #926A14
$blue: #21215A
$red: #B9525A
$cyan: #515BB3

$green: #00c793
$purple: #c53ed1
$gray-dark: #2e313d
$gray-800: $gray-dark
$gray: #61687f
$gray-600: $gray
$gray-light: #8c99a5
$gray-900: #61747f
$alt-white: #f3f7f9
$black: #000000
$white: #ffffff

$primary: $blue
$secondary: $gold